import axios from "axios";
import React, { useEffect, useState } from "react";
import MainForm from "./MainForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export default function MarketForm(props) {
  const [formProps, setFormProps] = useState(null);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/market/form`).then((res) => {
      let rawFields = {};
      if (res.data.length) {
        const rawData = res.data[0];
        const keys = Object.keys(rawData).filter((x) => x != "_id");
        for (let x = 0; x < keys.length; x++) {
          rawFields[keys[x]] = rawData[keys[x]];
        }
      }
      setFormProps(rawFields);
    });
  }, []);

  if (formProps)
    return (
      <MainForm
        {...props}
        fields={[
          {
            name: "category",
            required: true,
            label: "Категория",
            type: "category",
            extra: extra(formProps.category),
          },
          {
            name: "subcategory",
            required: true,
            label: "Подкатегория",
            type: "category",
            extra: extra(formProps.subcategory),
          },
          {
            name: "title",
            required: true,
            label: "Название",
            type: "string",
          },
          {
            name: "description",
            required: true,
            label: "Описание",
            type: "description",
          },
          {
            name: "text",
            required: true,
            label: "Текст",
            type: "text",
          },
          {
            name: "img",
            required: true,
            label: "Изображение",
            type: "img",
          },
          {
            name: "gallery",
            required: true,
            label: "Галерея",
            type: "gallery",
          },
          {
            name: "price",
            required: true,
            label: "Цена",
            type: "number",
          },
          {
            name: "color",
            required: true,
            label: "Цвет",
            type: "color",
            extra: formProps.color || [],
          },
          {
            name: "country",
            label: "Страна",
            type: "string",
          },
          {
            name: "vendor",
            required: true,
            label: "Артикул",
            type: "vendor",
          },
          {
            name: "date",
            required: true,
            label: "Дата",
            type: "date",
          },
        ]}
      />
    );
}

function extra(arg) {
  return arg && arg.length
    ? arg.map((x) => {
        return { value: x, label: x };
      })
    : [];
}
