import { styled } from "@mui/material";
import React from "react";
import { GiShoppingCart } from "react-icons/gi";

const CartBtn = styled(GiShoppingCart)(() => ({
  cursor: "pointer",
  fontSize: "16px",
}));

const ShoppingCart = ({ callBack }) => {
  return (
    <div onClick={callBack}>
      <CartBtn />
    </div>
  );
};

export default ShoppingCart;
