import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import auth from "../store/auth";
import DeleteModal from "../components/DeleteModal";
import NoData from "../components/NoData";
import ErrorBoundary from "../ErrorBoundary";
import AdvForm from "../components/AdvForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Advs = observer(() => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/banners`).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography
          variant="h5"
          onClick={(_) => {
            console.log("fields");
          }}
        >
          Advs list
        </Typography>
        <Card>
          <CardHeader
            sx={{ borderBottom: "1px solid #ddd" }}
            title={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <Typography>Advs list</Typography>
                <Button variant="contained" onClick={(_) => setVisible(true)}>
                  Add new
                </Button>
              </Box>
            }
          />
          <CardContent>
            {data && data.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((x) => (
                    <TableRow key={uuidv4()}>
                      <TableCell>
                        <Link to={`/banners/${x._id}`}>{x.title}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <DeleteModal
                          callBack={() => {
                            axios
                              .delete(
                                `${REACT_APP_API_PORT}api/banners/${x._id}`,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                    "x-auth-token": auth.token,
                                  },
                                }
                              )
                              .then(() => {
                                setData(data.filter((y) => y._id != x._id));
                                setMessage("Document deleted");
                              })
                              .catch((err) => {
                                console.log(err);
                                // logout
                                auth.logout();
                              });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoData />
            )}
          </CardContent>
        </Card>
      </Stack>
      <Drawer
        anchor="right"
        open={visible}
        onClose={(_) => setVisible(false)}
        PaperProps={{
          sx: { width: "100%", maxWidth: "555px", padding: "25px 15px" },
        }}
      >
        <ErrorBoundary>
          <AdvForm
            root="common"
            callBack={(args) => {
              axios
                .post(`${REACT_APP_API_PORT}api/banners`, args, {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": auth.token,
                  },
                })
                .then((res) => {
                  setMessage("Document created successfully");
                  setVisible(false);
                  setData([...data, res.data]);
                })
                .catch((err) => {
                  console.log(err);
                  // logout
                  auth.logout();
                });
            }}
          />
        </ErrorBoundary>
      </Drawer>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default Advs;
