import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { useIsMount } from "../misc/tools";

const dateFormat = "DD.MM.YYYY";

export default function DatePicker({ callBack, date }) {
  const isMount = useIsMount();
  const [value, setValue] = useState(
    dayjs(date ? date : new Date().toISOString())
  );

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!isMount) {
      callBack(new Date(value).toISOString());
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label="Дата"
        inputFormat={dateFormat}
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
