import React, { Fragment, useEffect } from 'react'
import { observer } from "mobx-react";
import auth from "./store/auth";
import axios from "axios";
import Private from './Private';
import Login from './Login';

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const App = observer(() => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    const avatar = localStorage.getItem("avatar");
    if (token && token != "") {
      axios
        .post(
          `${REACT_APP_API_PORT}api/auth/check`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": token,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          auth.setAuth(avatar, token);
        })
        .catch((err) => {
          console.log(err);
          auth.setAuth(null, null);
        });
    }
    // main.getData();
  }, []);

  return (
    <Fragment>
      {auth.token ? (
        <Private />
      ) : (
        <Login />
      )}
    </Fragment>
  )
})

export default App