import React, { useState } from "react";
import { Button, Stack } from "@mui/material";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import "codemirror/theme/neat.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/javascript/javascript.js";

const options = {
  mode: "xml",
  theme: "monokai",
  scrollbarStyle: "null",
  lineNumbers: true,
};

export default function CodeForm({ callBack, data }) {
  const [code, setCode] = useState(JSON.stringify(data, null, 2));
  return (
    <Stack
      spacing={2}
      sx={{
        ".CodeMirror": {
          height: "auto !important",
          minHeight: "300px",
        },
      }}
    >
      <CodeMirror
        value={JSON.stringify(data, null, 2)}
        options={options}
        onChange={(editor, _, value) => {
          setCode(value);
        }}
      />
      <Button
        variant="contained"
        onClick={() => {
          callBack(code);
        }}
        sx={{ width: "max-content" }}
      >
        Submit
      </Button>
    </Stack>
  );
}
