import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import auth from "../store/auth";
import VideoForm from "../components/VideoForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Video = observer(() => {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/videos/${id}`)
      .then((res) => setData(res.data));
  }, []);

  return (
    <Fragment>
      {data && (
        <Stack spacing={2} sx={{ pb: 4 }}>
          <Typography
            onClick={(_) => console.log(data)}
            variant="h5"
          >{`Video ${data.title}`}</Typography>
          <VideoPrev id={data.videoId} />
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={`Video ${data.title}`}
            />
            <CardContent>
              <VideoForm
                data={data}
                callBack={(args) => {
                  axios
                    .patch(`${REACT_APP_API_PORT}api/videos/${id}`, args, {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    })
                    .then((res) => {
                      setMessage("Document updated successfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      // logout
                      auth.logout();
                    });
                }}
              />
            </CardContent>
          </Card>
        </Stack>
      )}
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

function VideoPrev({ id }) {
  return (
    <Box
      sx={{
        width: "250px",
        margin: "0 auto",
        marginBottom: "20px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          paddingBottom: "56.22%",
          paddingTop: "30px",
          height: 0,
          overflow: "hidden",
          "iframe, object, embed": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <iframe
          width="1250"
          height="703"
          allowfullscreen="allowfullscreen"
          src={`https://www.youtube.com/embed/${id}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write;encrypted-media; gyroscope; picture-in-picture"
        />
      </Box>
    </Box>
  );
}

export default Video;
