import { makeAutoObservable, runInAction } from "mobx";

class auth {
  avatar = null;
  token = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(avatar, token) {
    this.avatar = avatar;
    this.token = token;
    localStorage.setItem("avatar", avatar);
    localStorage.setItem("token", token);
  }

  logout() {
    this.avatar = null;
    this.token = null;
    localStorage.setItem("avatar", "");
    localStorage.setItem("token", "");
  }
}

export default new auth();
