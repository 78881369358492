import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import auth from "../store/auth";
import HeroForm from "../components/HeroForm";
import ErrorBoundary from "../ErrorBoundary";
import SkillForm from "../components/SkillForm";
import NoData from "../components/NoData";
import DeleteModal from "../components/DeleteModal";
import Skills from "../components/Skills";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Hero = observer(() => {
  const { id } = useParams();
  // этот параметр важен, тк передает id героя, которому надо добавить id skill:
  const [heroId, setHeroId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [increment, setIncrement] = useState(0);

  // контроль аккордеона:
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/heroes/data/${id}`)
      .then((res) => setData(res.data));
  }, [increment]);
  useEffect(() => {
    setHeroId(id);
  }, [id]);

  return (
    <Fragment>
      {data && (
        <Stack spacing={2} sx={{ pb: 4 }}>
          <Typography variant="h5">{`Hero ${data.name}`}</Typography>
          <Paper
            elevation={0}
            sx={{
              border: "1px solid #ddd",
              p: 2,
              backgroundColor: "#CFB997",
            }}
          >
            <Typography>
              Данная ветка имеет свои особенности. Связь Hero и Skills не такая,
              как например с комментариями. При создании скилла в первый раз,
              также обновляется герой, кготорый его и получает. Однако этот
              скилл можно дать и другому, выбрав из списка в Modal. При удалении
              из аккордеона он удаляется только у героя. А в модальном списке -
              наоборот. Удаляется скилл, не затрагивая "носителей".
            </Typography>
          </Paper>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={`Hero ${data.name}`}
            />
            <CardContent>
              <HeroForm
                data={data}
                callBack={(args) => {
                  axios
                    .patch(`${REACT_APP_API_PORT}api/heroes/${id}`, args, {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    })
                    .then((res) => {
                      setMessage("Document updated successfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      // logout
                      auth.logout();
                    });
                }}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <Typography>Hero skills</Typography>
                  <Button variant="contained" onClick={(_) => setVisible(true)}>
                    Add new
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={(_) => {
                      setVisible2(true);
                    }}
                  >
                    Choose existing
                  </Button>
                </Box>
              }
            />
            <ErrorBoundary>
              <CardContent>
                {data.skills && data.skills.length ? (
                  <Fragment>
                    {data.skills.map((x) => (
                      <Accordion
                        expanded={expanded == x._id}
                        onChange={handleChange(x._id)}
                      >
                        <AccordionSummary
                          aria-controls={`${x._id}-content`}
                          id={`${x._id}-header`}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{x.name}</Typography>
                            <DeleteModal
                              callBack={() => {
                                axios
                                  .delete(
                                    `${REACT_APP_API_PORT}api/skills/${x._id}`,
                                    {
                                      headers: {
                                        "Content-Type": "application/json",
                                        hero_id: heroId,
                                        "x-auth-token": auth.token,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                    setData({
                                      ...data,
                                      skills: data.skills.filter(
                                        (y) => y._id != x._id
                                      ),
                                    });
                                    setMessage("Document deleted");
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    // logout
                                    auth.logout();
                                  });
                              }}
                            />
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <SkillForm
                            data={x}
                            callBack={(args) => {
                              axios
                                .patch(
                                  `${REACT_APP_API_PORT}api/skills/${x._id}`,
                                  args,
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                      "x-auth-token": auth.token,
                                    },
                                  }
                                )
                                .then((res) => {
                                  setMessage("Document updated sucessfully");
                                })
                                .catch((err) => {
                                  console.log(err);
                                  // logout
                                  auth.logout();
                                });
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Fragment>
                ) : (
                  <NoData />
                )}
              </CardContent>
            </ErrorBoundary>
          </Card>
        </Stack>
      )}
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
      <Drawer
        anchor="right"
        open={visible}
        onClose={(_) => setVisible(false)}
        PaperProps={{
          sx: { width: "100%", maxWidth: "555px", padding: "25px 15px" },
        }}
      >
        <ErrorBoundary>
          <SkillForm
            root="temp"
            callBack={(args) => {
              axios
                .post(
                  `${REACT_APP_API_PORT}api/skills`,
                  { ...args, heroId: heroId },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "x-auth-token": auth.token,
                    },
                  }
                )
                .then((res) => {
                  setMessage("Document created successfully");
                  setIncrement(increment + 1);
                })
                .catch((err) => {
                  console.log(err);
                  // logout
                  auth.logout();
                });
            }}
          />
        </ErrorBoundary>
      </Drawer>
      <Modal
        open={visible2}
        onClose={(_) => setVisible2(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Skills
          callBack={(arg) => {
            axios
              .patch(
                `${REACT_APP_API_PORT}api/heroes/${id}`,
                { skillId: arg },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": auth.token,
                  },
                }
              )
              .then((res) => {
                setIncrement(increment + 1);
                setMessage("Document updated successfully");
              })
              .catch((err) => {
                console.log(err);
                // logout
                auth.logout();
              });
          }}
        />
      </Modal>
    </Fragment>
  );
});

export default Hero;
