import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import MainForm from "./MainForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export default function UserForm(props) {
  const [formProps, setFormProps] = useState(null);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/heroes/form`).then((res) => {
      let rawFields = {};
      if (res.data.length) {
        const rawData = res.data[0];
        const keys = Object.keys(rawData).filter((x) => x != "_id");
        for (let x = 0; x < keys.length; x++) {
          rawFields[keys[x]] = rawData[keys[x]];
        }
      }
      setFormProps(rawFields);
    });
  }, []);

  if (formProps)
    return (
      <Fragment>
        <MainForm
          {...props}
          fields={[
            {
              name: "name",
              required: true,
              label: "Имя",
              type: "string",
            },
            {
              name: "email",
              required: true,
              label: "Email",
              type: "string",
            },
            {
              name: "password",
              required: true,
              label: "Пароль",
              type: "password",
            },
            {
              name: "date",
              required: true,
              label: "Дата",
              type: "date",
            },
          ]}
        />
      </Fragment>
    );
}