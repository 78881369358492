import React, { useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import auth from "./store/auth";
import axios from "axios";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Login = observer(() => {
  const [state, setState] = useState({ email: "", password: "" });
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (
    <Stack sx={{ padding: "50px" }} spacing={2}>
      <Typography variant="h4">Type your login and password</Typography>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          axios
            .post(`${REACT_APP_API_PORT}api/auth`, state)
            .then((res) => {
              auth.setAuth(res.data.avatar, res.data.token);
            })
            .catch((err) => {
              console.log(err);
              alert("Incorrect login or password");
            });

        }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Login (email)"
              name="email"
              value={state.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={state.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
});

export default Login;
