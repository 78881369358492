import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Snackbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import DeleteModal from "./DeleteModal";
import { observer } from "mobx-react";
import auth from "../store/auth";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#121212",
  color: "#fff",
  border: "2px solid #000",
  boxShadow: 24,
};

const Skills = observer(({ callBack }) => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/skills`).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <Fragment>
      <Card sx={style}>
        <CardHeader
          title="Skills list"
          sx={{ borderBottom: "1px solid #2C3639" }}
        />
        <CardContent sx={{ padding: "0 !important" }}>
          {data && (
            <List>
              {data.map((x) => (
                <ListItem
                  disablePadding
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteModal
                        callBack={() => {
                          axios
                            .delete(
                              `${REACT_APP_API_PORT}api/skills/${x._id}`,
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                  "x-auth-token": auth.token,
                                },
                              }
                            )
                            .then(() => {
                              setData(data.filter((y) => y._id != x._id));
                              setMessage("Document deleted");
                            })
                            .catch((err) => {
                              console.log(err);
                              // logout
                              auth.logout();
                            });
                        }}
                      />
                    </IconButton>
                  }
                >
                  <ListItemButton>
                    <ListItemText
                      primary={x.name}
                      onClick={(_) => callBack(x._id)}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default Skills;
