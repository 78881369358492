import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import auth from "../store/auth";
import MarketForm from "../components/MarketForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const MarketItem = observer(() => {
  const { id } = useParams();
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/market/data/${id}`)
      .then((res) => setData(res.data));
  }, []);

  return (
    <Fragment>
      {data && (
        <Stack spacing={2} sx={{ pb: 4 }}>
          <Typography variant="h5">{`Item ${data.title}`}</Typography>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={`Item ${data.title}`}
            />
            <CardContent>
              <MarketForm
                data={data}
                callBack={(args) => {
                  axios
                    .patch(`${REACT_APP_API_PORT}api/market/${id}`, args, {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    })
                    .then((res) => {
                      setMessage("Document updated successfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      // logout
                      auth.logout();
                    });
                }}
              />
            </CardContent>
          </Card>          
        </Stack>
      )}
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default MarketItem;
