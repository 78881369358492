import axios from "axios";
import React, { useEffect, useState } from "react";
import MainForm from "./MainForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

export default function PostForm(props) {
  // const [formProps, setFormProps] = useState(null);
  // useEffect(() => {
  //   axios.get(`${REACT_APP_API_PORT}api/heroes/form`).then((res) => {
  //     let rawFields = {};
  //     if (res.data.length) {
  //       const rawData = res.data[0];
  //       const keys = Object.keys(rawData).filter((x) => x != "_id");
  //       for (let x = 0; x < keys.length; x++) {
  //         rawFields[keys[x]] = rawData[keys[x]];
  //       }
  //     }
  //     setFormProps(rawFields);
  //   });
  // }, []);

  // if (formProps)
    return (
      <MainForm
        {...props}
        fields={[
          {
            name: "title",
            required: true,
            label: "Заголовок",
            type: "string",
          },
          {
            name: "description",
            required: true,
            label: "Описание",
            type: "description",
          },
          {
            name: "text",
            required: true,
            label: "Текст",
            type: "text",
          },
          {
            name: "img",
            required: true,
            label: "Изображение",
            type: "img",
          },
          {
            name: "date",
            required: true,
            label: "Дата",
            type: "date",
          },
        ]}
      />
    );
}
