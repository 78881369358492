import { Box, Modal, styled, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { SlPicture } from "react-icons/sl";
import FileLoad from "./FileLoad";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const ModalWrapper = styled("div")(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "1111px",
}));

export default function ImageModal({
  clearTemp,
  defaultPhoto,
  callBack,
  root,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [photo, setPhoto] = useState(defaultPhoto ? defaultPhoto : null);

  return (
    <Fragment>
      <Box
        sx={{
          fontSize: "100px",
          lineHeight: 0,
          cursor: "pointer",
          width: "max-content",
          border: "1px solid #ddd",
          borderRadius: "4px",
          overflow: "hidden",
        }}
        onClick={handleOpen}
      >
        {photo ? (
          <img
            width={200}
            src={REACT_APP_API_PORT + "dir/" + root + "/" + photo}
          />
        ) : (
          <SlPicture />
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapper>
          <FileLoad
            callBack={(arg) => {
              setPhoto(arg);
              callBack(arg);
            }}
            root={root}
            clearTemp={clearTemp}
            title="Upload files"
          >
            {photo && (
              <img
                width={200}
                src={REACT_APP_API_PORT + "dir/" + root + "/" + photo}
                style={{ marginBottom: "25px", cursor: "pointer" }}
              />
            )}
          </FileLoad>
        </ModalWrapper>
      </Modal>
    </Fragment>
  );
}
