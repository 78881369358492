import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { GiEmptyWoodBucketHandle } from "react-icons/gi";

const StyledSvg = styled(GiEmptyWoodBucketHandle)(() => ({
  fontSize: "75px",
}));

export default function NoData() {
  return (
    <Box
      sx={{
        padding: "75px 0",
        textAlign: "center",
        color: "#d0d0d0",
      }}
    >
      <StyledSvg />
      <Typography variant="h5">Нет данных</Typography>
    </Box>
  );
}
