import axios from "axios";
import React, { useEffect, useState } from "react";
import MainForm from "./MainForm";

export default function SlideForm(props) {
  return (
    <MainForm
      {...props}
      fields={[
        {
          name: "title",
          required: true,
          label: "Заголовок",
          type: "string",
        },
        {
          name: "link1",
          required: true,
          label: "Ссылка 1",
          type: "string",
        },
        {
          name: "link2",
          required: true,
          label: "Ссылка 2",
          type: "string",
        },
        {
          name: "img",
          required: true,
          label: "Изображение",
          type: "img",
        },
        {
          name: "date",
          required: true,
          label: "Дата",
          type: "date",
        },
      ]}
    />
  );
}
