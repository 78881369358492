import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const CloseBtn = styled(IoIosCloseCircleOutline)(() => ({
  cursor: "pointer",
  color: "brown",
  fontSize: "16px",
}));

export default function DeleteModal({ callBack, style }) {
  const [visible, setVisible] = useState(false);
  return (
    <Fragment>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setVisible(true);
        }}
        sx={style}
      >
        <CloseBtn />
      </Box>
      <Dialog open={visible} onClose={() => setVisible(false)}>
        <DialogTitle>"Are you sure to delete the document"</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              setVisible(false);
            }}
          >
            Disagree
          </Button>
          <Button
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              callBack();
              setVisible(false);
            }}
            autoFocus
            color="error"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
