import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import DeleteModal from "../components/DeleteModal";
import NoData from "../components/NoData";
import { Box } from "@mui/system";
import ShoppingCart from "../components/ShoppingCart";
import { BsFillDashSquareFill, BsFillPlusSquareFill } from "react-icons/bs";
import { useIsMount } from "../misc/tools";
import MainForm from "../components/MainForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const TestCart = observer(() => {
  const [cart, setCart] = useState([]);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/market/data`).then((res) => {
      setData(res.data);
    });
  }, []);

  function order(customerData, items) {
    const html = `<h3>Информация о заказе:</h3>
    <p><b>Общая сумма:  </b><span>${items
      .map((x) => Number(x.price))
      .reduce((a, b) => a + b)} р.</span></p>
    <p><b>Заказчик:  </b><span>${customerData.name}</span></p>
    <p><b>Email:  </b><span>${customerData.email}</span></p>
    <p><b>Телефон:  </b><span>${customerData.phone}</span></p>
    <p><b>Текст:  </b><span>${message}</span></p><br />
    <h3>Товары:</h3>
    ${items.map((x) => {
      return `<div style="display: grid; grid-template-columns: 1fr 4fr; grid-column-gap: 20px;">
        <img style="width: 100%" src="${REACT_APP_API_PORT}/dir/${x.img.dir}/${x.img.file}" alt="">
        <div>
          <h5><a href="${REACT_APP_API_PORT}/items/${x._id}" target="_blank" rel="noopener noreferrer">${x.title}</a></h5>          
          <p><b>id товара:  </b><span>${x._id}</span></p>
          <p><b>Артикул товара:  </b><span>${x.vendor}</span></p>
          <p><b>Количество:  </b><span>${x.quantity}</span></p>
        </div>
      </div>`;
    })}
    `;
    axios
      .post(
        `${REACT_APP_API_PORT}api/order`,
        { html: html },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (res) => {
        setCart([]);
        setMessage("Успех");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography
          variant="h5"
          onClick={(_) => {
            console.log("fields");
          }}
        >
          Market list
        </Typography>
        <Card>
          <CardHeader
            sx={{ borderBottom: "1px solid #ddd" }}
            title="Test shopping cart"
          />
          <CardContent>
            {data && data.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((x) => (
                    <TableRow key={uuidv4()}>
                      <TableCell>{x.title}</TableCell>
                      <TableCell align="right">
                        <ShoppingCart
                          callBack={(_) => {
                            setCart([...cart, { ...x, quantity: 1 }]);
                            setMessage("Item added");
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoData />
            )}
          </CardContent>
        </Card>
        {cart.length ? (
          <Fragment>
            <Card>
              <CardHeader
                sx={{ borderBottom: "1px solid #ddd" }}
                title="Shopping cart"
              />
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Name</b>
                      </TableCell>
                      <TableCell>Change amount</TableCell>
                      <TableCell align="right">Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cart.map((x, i) => (
                      <TableRow key={uuidv4()}>
                        <TableCell>{x.title}</TableCell>
                        <TableCell>
                          <Counter
                            callBack={(arg) => {
                              let cartProxy = cart;
                              cartProxy[i].quantity = arg;
                              setCart(cartProxy);
                            }}
                            value={x.quantity}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <DeleteModal
                            callBack={(_) => {
                              setCart(cart.filter((y) => y._id != x._id));
                              setMessage("Cart removed");
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                sx={{ borderBottom: "1px solid #ddd" }}
                title="Purchase"
              />
              <CardContent>
                <PurchaseForm
                  callBack={(args) => {
                    order(args, cart);
                  }}
                />
              </CardContent>
            </Card>
          </Fragment>
        ) : (
          ""
        )}
      </Stack>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

function Counter({ callBack, value }) {
  const isMount = useIsMount();
  const [counter, setCounter] = useState(value);
  useEffect(() => {
    if (!isMount) {
      callBack(counter);
    }
  }, [counter]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        columnGap: "10px",
        alignItems: "center",
        svg: { color: "green", fontSize: "22px", cursor: "pointer" },
      }}
    >
      <BsFillDashSquareFill
        onClick={(_) => {
          if (counter > 0) setCounter(counter - 1);
        }}
      />
      {counter}
      <BsFillPlusSquareFill
        onClick={(_) => {
          setCounter(counter + 1);
        }}
      />
    </Box>
  );
}

function PurchaseForm(props) {
  return (
    <MainForm
      {...props}
      fields={[
        {
          name: "name",
          required: true,
          label: "Имя",
          type: "string",
        },
        {
          name: "email",
          required: true,
          label: "Email",
          type: "string",
        },
        {
          name: "phone",
          required: true,
          label: "Phone",
          type: "string",
        },
        {
          name: "message",
          required: true,
          label: "Описание",
          type: "description",
        },
      ]}
    />
  );
}

export default TestCart;
