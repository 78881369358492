// Универсальная форма, принимает пропс "fields" для формирования полей:
import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import ImageModal from "./ImageModal";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { GrUpdate } from "react-icons/gr";
import { paper } from "../misc/colors";
import TextEditor from "./TextEditor";
import DatePicker from "./DatePicker";
import GalleryModal from "./GalleryModal";
import { makeId, useIsMount } from "../misc/tools";

function MainForm({ callBack, data, fields, root }) {
  const [message, setMessage] = useState("");
  // данный параметр регулирует, очищать ли папку temp при открытии Modal с менеджером FileLoad:
  const [clearTemp, setClearTemp] = useState(root == "temp" ? true : false);
  // Данные формы. Важно - img подстраивается под тип object и string!!!
  const [state, setState] = useState(null);
  useEffect(() => {
    if (root == "temp") setClearTemp(true);
    function checkFieldExisted(arg) {
      return fields.some((field) => field.name == arg);
    }
    let stateProxy = {};
    if (data) {
      stateProxy = Object.assign({}, data);
      if (data.img) {
        console.log(data.img);
        stateProxy.img = typeof data.img == "object" ? data.img.file : data.img;
      }
      if (data.date) {
        stateProxy.date = data.date;
      }
      if (data.color) {
        stateProxy.color = { code: data.color.code, name: data.color.name };
      }
    } else {
      if (checkFieldExisted("date")) {
        stateProxy.date = new Date().toISOString();
      }
      if (checkFieldExisted("color")) {
        stateProxy.color = { code: "", name: "" };
      }
    }
    setState(stateProxy);
    return () => {
      if (root == "temp") setClearTemp(true);
    };
  }, []);

  function checkRequired(fields, state) {
    for (let x = 0; x < fields.length; x++) {
      if (!state[fields[x].name]) return false;
    }
    return true;
  }
  function submit(e) {
    e.preventDefault();
    if (!checkRequired(fields, state)) {
      setMessage("Пожалуйста заполните необходимые поля");
    } else {
      callBack(state);
      setMessage("");
    }
  }

  return (
    <Fragment>
      <form onSubmit={submit}>
        <Stack spacing={3}>
          {fields.length &&
            state &&
            fields.map((x) => (
              <RenderItem
                {...x}
                misc={{
                  root:
                    data && typeof data.img == "object" ? data.img.dir : root,
                  clearTemp,
                }}
                value={state[x.name]}
                callBack={(field, value) => {
                  setState({ ...state, [field]: value });
                  if (x.type == "img" || x.type == "gallery")
                    setClearTemp(false);
                }}
              />
            ))}
          <Button
            variant="contained"
            type="submit"
            sx={{ width: "max-content" }}
          >
            Save
          </Button>
        </Stack>
      </form>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
}

function RenderItem({ callBack, extra, label, misc, name, type, value }) {
  switch (type) {
    case "string":
      return (
        <TextField
          label={label}
          variant="outlined"
          value={value}
          onChange={(e) => callBack(name, e.target.value)}
        />
      );

    case "description":
      return (
        <Box sx={{ borderRadius: "4px", overflow: "hidden" }}>
          <TextField
            label={label}
            multiline
            rows={4}
            value={value}
            variant="filled"
            onChange={(e) => callBack(name, e.target.value)}
            sx={{ width: "100%" }}
          />
        </Box>
      );

    case "text":
      return (
        <TextEditor
          value={value}
          callBack={(arg) => {
            callBack(name, arg);
          }}
        />
      );

    case "number":
      return (
        <TextField
          label={label}
          variant="outlined"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          value={value}
          onChange={(e) => callBack(name, e.target.value)}
        />
      );

    case "img":
      return (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <ImageModal
            callBack={(arg) => {
              callBack(name, arg);
            }}
            defaultPhoto={value}
            root={misc.root}
            clearTemp={misc.clearTemp}
          />
        </FormControl>
      );

    case "gallery":
      return (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <GalleryModal
            callBack={(arg) => {
              callBack(name, arg);
            }}
            gallery={value}
            root={misc.root}
            clearTemp={misc.clearTemp}
          />
        </FormControl>
      );

    case "date":
      return (
        <DatePicker
          date={value}
          callBack={(arg) => {
            callBack(name, arg);
          }}
        />
      );

    case "radio":
      return (
        <RadioField
          callBack={(arg) => callBack(name, arg)}
          extra={extra}
          label={label}
          value={value}
        />
      );

    case "category":
      return (
        <Categories
          callBack={(arg) => callBack(name, arg)}
          extra={extra}
          label={label}
          value={value}
        />
      );

    case "color":
      return (
        <ColorPicker
          callBack={(arg) => callBack(name, { code: arg.code, name: arg.name })}
          extra={extra}
          label={label}
          value={value}
        />
      );

    case "vendor":
      return (
        <Vendor
          label={label}
          value={value}
          callBack={(arg) => callBack(name, arg)}
        />
      );

    default:
      return "";
  }
}

function RadioField({ callBack, extra, label, value }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [radios, setRadios] = useState(extra);
  const [text, setText] = useState("");
  const [shutruk, setShutruk] = useState(value);
  useEffect(() => {
    setShutruk(value);
  }, [value]);

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ p: 2, backgroundColor: paper }}
    >
      <FormControl>
        <FormLabel>{label}</FormLabel>
        {!isUpdating && (
          <Fragment>
            {radios && radios.length ? (
              <RadioGroup
                onChange={(e) => callBack(e.target.value)}
                defaultValue={value}
                row
              >
                {radios.map((x) => (
                  <FormControlLabel
                    value={x.value}
                    control={<Radio />}
                    label={x.label}
                  />
                ))}
              </RadioGroup>
            ) : (
              ""
            )}
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                // width: 400,
              }}
              variant="outlined"
            >
              <TextField
                variant="standard"
                value={text}
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{ paddingLeft: "10px" }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="add"
                onClick={(_) => {
                  setRadios([...radios, { label: text, value: text }]);
                  setText("");
                }}
              >
                <AiOutlinePlusCircle />
              </IconButton>
            </Paper>
          </Fragment>
        )}
        <Paper
          component="form"
          elevation={0}
          sx={{
            mt: 1,
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            // width: 400,
            height: "50px",
          }}
          variant="outlined"
        >
          <TextField
            value={shutruk}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ paddingLeft: "10px" }}
            onChange={(e) => {
              if (e.target.value.length) {
                setIsUpdating(true);
              } else {
                setIsUpdating(false);
              }
              setShutruk(e.target.value);
              callBack(e.target.value);
            }}
          />
        </Paper>
      </FormControl>
    </Paper>
  );
}

function Categories({ callBack, extra, label, value }) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [options, setOptions] = useState(extra);
  const [text, setText] = useState("");
  const [shutruk, setShutruk] = useState(value);
  useEffect(() => {
    setShutruk(value);
  }, [value]);

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ p: 2, backgroundColor: paper }}
    >
      <FormControl>
        <FormLabel>{label}</FormLabel>
        {!isUpdating && (
          <Fragment>
            {options && options.length ? (
              <Select
                value={value}
                label={label}
                onChange={(e) => callBack(e.target.value)}
                sx={{ mb: 1 }}
              >
                {options.map((x) => (
                  <MenuItem value={x.value}>{x.label}</MenuItem>
                ))}
              </Select>
            ) : (
              ""
            )}
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
              variant="outlined"
            >
              <TextField
                variant="standard"
                value={text}
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder="Добавить"
                sx={{ paddingLeft: "10px" }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="add"
                onClick={(_) => {
                  setOptions([...options, { label: text, value: text }]);
                  setText("");
                }}
              >
                <AiOutlinePlusCircle />
              </IconButton>
            </Paper>
          </Fragment>
        )}
        <Paper
          component="form"
          elevation={0}
          sx={{
            mt: 1,
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            height: "50px",
          }}
          variant="outlined"
        >
          <TextField
            value={shutruk}
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{ paddingLeft: "10px" }}
            onChange={(e) => {
              if (e.target.value.length) {
                setIsUpdating(true);
              } else {
                setIsUpdating(false);
              }
              setShutruk(e.target.value);
              callBack(e.target.value);
            }}
          />
        </Paper>
      </FormControl>
    </Paper>
  );
}

function ColorPicker({ callBack, extra, label, value }) {
  const [options, setOptions] = useState(extra);
  const [clrCode, setClrCode] = useState(value ? value.code : "");
  const [clrName, setClrName] = useState(value ? value.name : "");

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ p: 2, backgroundColor: paper }}
    >
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridColumnGap: "10px",
          }}
        >
          {/* <h3
            onClick={(_) => {
              console.log(value);
              console.log(options);
            }}
          >
            jhgkjhgkjhgkjh333
          </h3> */}
          {options && options.length ? (
            <Select
              value={value.code}
              label={label}
              onChange={(e) =>
                callBack(options.filter((x) => x.code == e.target.value)[0])
              }
              sx={{ height: "min-content" }}
            >
              {options.map((x) => (
                <MenuItem value={x.code}>
                  <Box sx={{ display: "flex", columnGap: "8px" }}>
                    <Box
                      sx={{
                        backgroundColor: x.code,
                        height: "20px",
                        width: "20px",
                      }}
                    />
                    {x.name}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          ) : (
            ""
          )}
          <Stack direction="column" spacing={1}>
            <TextField
              label="Код"
              variant="outlined"
              value={clrCode}
              onChange={(e) => setClrCode(e.target.value)}
            />
            <TextField
              label="Название"
              variant="outlined"
              value={clrName}
              onChange={(e) => setClrName(e.target.value)}
            />
            <Button
              variant="outlined"
              sx={{ width: "max-content" }}
              onClick={(_) => {
                setOptions([...options, { code: clrCode, name: clrName }]);
                setClrCode("");
                setClrName("");
              }}
            >
              Добавить новый
            </Button>
          </Stack>
        </Box>
      </FormControl>
    </Paper>
  );
}

function Vendor({ callBack, label, value }) {
  const [vendor, setVendor] = useState(value ? value : makeId(20));
  useEffect(() => {
    callBack(vendor);
  }, [vendor]);

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ p: 2, backgroundColor: paper }}
    >
      <FormControl>
        <FormLabel>{label}</FormLabel>
      </FormControl>
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: "2px 4px",
          display: "grid",
          alignItems: "center",
          gridTemplateColumns: "auto min-content",
        }}
        variant="outlined"
      >
        <TextField
          variant="standard"
          value={vendor}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder="Артикул"
          sx={{ paddingLeft: "10px" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="update"
          onClick={(_) => {
            setVendor(makeId(20));
          }}
        >
          <GrUpdate />
        </IconButton>
      </Paper>
    </Paper>
  );
}

export default MainForm;
