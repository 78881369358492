import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import auth from "../store/auth";
import PostForm from "../components/PostForm";
import ErrorBoundary from "../ErrorBoundary";
// import SkillForm from "../components/SkillForm";
import NoData from "../components/NoData";
import DeleteModal from "../components/DeleteModal";
import SendToSubscribers from "../components/SendToSubscribers";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Item = observer(() => {
  const { id } = useParams();
  // этот параметр важен, тк передает id героя, которому надо добавить id skill:
  const [postId, setPostId] = useState(null);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [increment, setIncrement] = useState(0);

  // контроль аккордеона:
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/posts/${id}`)
      .then((res) => setData(res.data));
  }, [increment]);
  useEffect(() => {
    setPostId(id);
  }, [id]);

  return (
    <Fragment>
      {data && (
        <Stack spacing={2} sx={{ pb: 4 }}>
          <Typography variant="h5">{`Post ${data.title}`}</Typography>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={`Post ${data.title}`}
            />
            <CardContent>
              <PostForm
                data={data}
                callBack={(args) => {
                  axios
                    .patch(`${REACT_APP_API_PORT}api/posts/${id}`, args, {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    })
                    .then((res) => {
                      setMessage("Document updated successfully");
                    })
                    .catch((err) => {
                      console.log(err);
                      // logout
                      auth.logout();
                    });
                }}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title="Post comments"
            />
            <ErrorBoundary>
              <CardContent>
                {data.comments && data.comments.length ? (
                  <Fragment>
                    {data.comments.map((x) => (
                      <Accordion
                        expanded={expanded == x._id}
                        onChange={handleChange(x._id)}
                      >
                        <AccordionSummary
                          aria-controls={`${x._id}-content`}
                          id={`${x._id}-header`}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{x.author}</Typography>
                            <DeleteModal
                              callBack={() => {
                                axios
                                  .delete(
                                    `${REACT_APP_API_PORT}api/comments/${x._id}`,
                                    {
                                      headers: {
                                        "Content-Type": "application/json",
                                        post_id: postId,
                                        "x-auth-token": auth.token,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                    setData({
                                      ...data,
                                      comments: data.comments.filter(
                                        (y) => y._id != x._id
                                      ),
                                    });
                                    setMessage("Document deleted");
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    // logout
                                    auth.logout();
                                  });
                              }}
                            />
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{x.text}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Fragment>
                ) : (
                  <NoData />
                )}
              </CardContent>
            </ErrorBoundary>
          </Card>
          <SendToSubscribers
            data={`          
            <h3><a href="${REACT_APP_API_PORT}posts/${
              data._id
            }" target="_blank" rel="noopener noreferrer">${data.title}</a></h3>
            <img src="${REACT_APP_API_PORT}dir/${data.img.dir}/${
              data.img.file
            }" alt="" style="width: 300px; max-width: 100%">
            <p><b>Дата:  </b><span>${data.date.slice(0, 12)}</span></p>
          `}
          />
        </Stack>
      )}
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default Item;
