import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import auth from "../store/auth";
import axios from "axios";
import CodeForm from "../components/CodeForm";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Snackbar,
} from "@mui/material";
import { IoCloseOutline } from "react-icons/io5";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;
const REACT_APP_DATA_ID = process.env.REACT_APP_DATA_ID;

const Main = observer(() => {
  // Message:
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Data
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/main/${REACT_APP_DATA_ID}`)
      .then((res) => {
        setData({ title: res.data.title, data: JSON.parse(res.data.data) });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader title="Main data" sx={{ borderBottom: "1px solid #ddd" }} />
        <CardContent>
          {data && (
            <CodeForm
              data={data.data}
              callBack={(arg) => {
                const parsedData = JSON.stringify(JSON.parse(arg));
                axios
                  .patch(
                    `${REACT_APP_API_PORT}api/main/${REACT_APP_DATA_ID}`,
                    { ...data, data: parsedData },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        "x-auth-token": auth.token,
                      },
                    }
                  )
                  .then((res) => {
                    handleClick();
                  })
                  .catch((err) => {
                    console.log(err);
                    // logout
                    auth.logout();
                  });
              }}
            />
          )}
        </CardContent>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <IoCloseOutline />
          </IconButton>
        }
      >
        <Alert severity="success">Data saved</Alert>
      </Snackbar>
    </Fragment>
  );
});

export default Main;
