import React from "react";
import MainForm from "./MainForm";

export default function SkillForm(props) {
  return (
    <MainForm
      {...props}
      fields={[
        {
          name: "name",
          required: true,
          label: "Имя",
          type: "string",
        },
        {
          name: "description",
          required: true,
          label: "Описание",
          type: "description",
        },
        {
          name: "img",
          required: true,
          label: "Изображение",
          type: "img",
        },
      ]}
    />
  );
}
