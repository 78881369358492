import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { useIsMount } from "../misc/tools";

export default function TextEditor({ callBack, value }) {
  const isMount = useIsMount();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(value ? value : "Текст"))
  );
  useEffect(() => {
    if (!isMount) {
      callBack(stateToHTML(editorState.getCurrentContent()));
    }
  }, [editorState]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={(e) => {
        setEditorState(e);
      }}
      editorStyle={{ padding: "0 10px" }}
      wrapperStyle={{
        border: "1px solid #ddd",
        borderRadius: "4px",
        overflow: "hidden",
      }}
      toolbarStyle={{
        borderLeft: "none",
        borderRight: "none",
        borderTop: "none",
      }}
    />
  );
}
