import React from "react";
import MainForm from "./MainForm";

export default function CommentForm(props) {
  return (
    <MainForm
      {...props}
      fields={[
        {
          name: "author",
          required: true,
          label: "Имя",
          type: "string",
        },
        {
          name: "text",
          required: true,
          label: "Описание",
          type: "description",
        },
      ]}
    />
  );
}