import React, { useState, Fragment, useEffect, useRef } from "react";
import { AiOutlineProfile } from "react-icons/ai";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import auth from "../store/auth";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Snackbar,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import DeleteModal from "./DeleteModal";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const PhotoIcon = styled(AiOutlineProfile)(() => ({
  visibility: "visible !important",
  opacity: "1 !important",
  color: "#888 !important",
}));

const StyledCard = styled(Card)(() => ({
  borderRadius: 0,
  boxShadow: "none",
  border: "1px solid #ddd",
  cursor: "pointer",
  svg: {
    color: "brown",
    transition: ".3s ease-in-out",
    visibility: "hidden",
    opacity: 0,
  },
  "&:hover svg": {
    visibility: "visible",
    opacity: 1,
  },
}));

const FileLoad = observer(({ callBack, children, clearTemp, root, title }) => {
  const [keep, setKeep] = useState(false);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const loadRef = useRef(null);

  useEffect(() => {
    axios
      .get(`${REACT_APP_API_PORT}api/files`, {
        headers: {
          "Content-Type": "application/json",
          root: root,
          "clear-temp": clearTemp,
        },
      })
      .then((res) => setData(res.data.filter((x) => x.length < 29))) // скрыть файлы thumb
      .catch((err) => console.log(err));
  }, []);

  function load(e) {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios
      .post(`${REACT_APP_API_PORT}api/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": auth.token,
          root: root,
          keep: keep,
        },
      })
      .then((res) => {
        setMessage("Document loaded successfully");
        setData([...data, res.data.name]);
        setKeep(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <Fragment>
      <Card>
        <CardHeader
          title={title || "Common files"}
          sx={{ borderBottom: "1px solid #ddd" }}
        />
        <CardContent>
          {children}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(100px, 200px))",
              gridColumnGap: "10px !important",
              gridRowGap: "10px",
            }}
          >
            {data.map((x) => {
              const isImage =
                fileExt(x) == ".jpg" ||
                fileExt(x) == ".jpeg" ||
                fileExt(x) == ".png" ||
                fileExt(x) == ".bmp" ||
                fileExt(x) == ".gif" ||
                fileExt(x) == ".tif";
              return (
                <StyledCard
                  key={uuidv4()}
                  onClick={() => {
                    if (isImage) {
                      callBack(x);
                    } else {
                      setMessage("The file is not an image");
                    }
                  }}
                >
                  <Box
                    sx={{
                      paddingBottom: "100%",
                      position: "relative",
                    }}
                  >
                    <DeleteModal
                      callBack={() => {
                        axios
                          .delete(`${REACT_APP_API_PORT}api/files`, {
                            headers: {
                              "Content-Type": "application/json",
                              "x-auth-token": auth.token,
                              filename: x,
                              root: root,
                            },
                          })
                          .then((res) => setData(data.filter((y) => y != x)))
                          .catch((err) => {
                            console.log(err);
                            // logout
                            auth.logout();
                          });
                      }}
                      style={{
                        position: "absolute",
                        top: "7px",
                        right: "7px",
                        zIndex: 1,
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        height: "86%",
                        width: "86%",
                        left: 0,
                        top: 0,
                        padding: "7%",
                        background: "#ddd",
                      }}
                    >
                      {isImage ? (
                        <Box
                          sx={{
                            height: "100%",
                            transition: "all 0.4s ease-in-out 0s",
                            width: "100%",
                            background: `url(${REACT_APP_API_PORT}dir${
                              root ? "/" + root + "/" : "/"
                            }${x}) center center / contain no-repeat`,
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            color: "#888",
                            height: "100%",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "100%",
                            justifyContent: "center",
                            rowGap: "10px",
                            fontSize: "45px",
                          }}
                        >
                          <PhotoIcon />
                          <Typography variant="h5">
                            Not an image type
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <CardContent sx={{ padding: "15px !important" }}>
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {x}
                    </Typography>
                  </CardContent>
                </StyledCard>
              );
            })}
          </Box>
        </CardContent>
        <CardActions sx={{ padding: "16px" }}>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" onClick={() => loadRef.current.click()}>
              Upload
            </Button>
            <FormControlLabel
              control={<Checkbox onChange={(e) => setKeep(!keep)} />}
              label="Keep original"
            />
          </Stack>
        </CardActions>
      </Card>
      <Box sx={{ visibility: "hidden" }}>
        <input
          type="file"
          id="file"
          name="file"
          onChange={load}
          ref={loadRef}
        />
      </Box>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

const fileExt = (name) => {
  return name.match(/\.[0-9a-z]+$/i)[0];
};

export default FileLoad;
