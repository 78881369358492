import React, { Fragment, useEffect, useState } from "react";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import FileLoad from "./FileLoad";
import DeleteModal from "./DeleteModal";
import { Box, Modal, Snackbar, styled } from "@mui/material";
import { useIsMount } from "../misc/tools";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const GalleryWrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridColumnGap: "10px",
  gridRowGap: "10px",
  marginTop: "20px",
}));

const GalleryItem = styled("div")(() => ({
  position: "relative",
  paddingBottom: "100%",
}));

const ImgWrapper = styled("div")(() => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  left: "0px",
  top: "0px",
  border: "1px solid #ddd",
}));

const ImgX = styled("div")(({ name, root }) => ({
  height: "100%",
  transition: "all 0.4s ease-in-out 0s",
  width: "100%",
  background: `url(${
    REACT_APP_API_PORT + "dir/" + root + "/" + name
  }) center center / contain no-repeat`,
}));

const ModalWrapper = styled("div")(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "1111px",
}));

function GalleryModal({ callBack, clearTemp, gallery, root }) {
  const isMount = useIsMount();
  const [data, setData] = useState(gallery ? gallery : []);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!isMount) {
      callBack(data);
    }
  }, [data]);

  return (
    <Fragment>
      <Box
        sx={{
          fontSize: "100px",
          lineHeight: 0,
          cursor: "pointer",
          width: "max-content",
          border: "1px solid #ddd",
          borderRadius: "4px",
          overflow: "hidden",
        }}
        onClick={handleOpen}
      >
        <AiOutlineAppstoreAdd />
      </Box>
      {data && data.length ? (
        <GalleryWrapper>
          {data.map((x) => (
            <GalleryItem key={x}>
              <ImgWrapper>
                <ImgX name={x} root={root} />
                <Box
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    zIndex: 1,
                  }}
                >
                  <DeleteModal
                    callBack={(_) => setData(data.filter((y) => y != x))}
                  />
                </Box>
              </ImgWrapper>
            </GalleryItem>
          ))}
        </GalleryWrapper>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalWrapper>
          <FileLoad
            callBack={(arg) => {
              if (data.some((elem) => elem == arg)) {
                setMessage("Изображение уже добавлено");
              } else {
                setData([...data, arg]);
              }
            }}
            root={root}
            clearTemp={clearTemp}
          />
        </ModalWrapper>
      </Modal>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
}

export default GalleryModal;
