import React, { Fragment } from "react";
import MainForm from "./MainForm";

export default function GalleryForm(props) {
  return (
    <Fragment>
      <MainForm
        {...props}
        fields={[
          {
            name: "title",
            required: true,
            label: "Заголовок",
            type: "string",
          },
          {
            name: "description",
            required: true,
            label: "Описание",
            type: "description",
          },          
          {
            name: "img",
            required: true,
            label: "Изображение",
            type: "img",
          },
          {
            name: "gallery",
            required: true,
            label: "Галерея",
            type: "gallery",
          },
          {
            name: "date",
            required: true,
            label: "Дата",
            type: "date",
          },
        ]}
      />
    </Fragment>
  );
}
