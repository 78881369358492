import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Drawer,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import auth from "../store/auth";
import ErrorBoundary from "../ErrorBoundary";
import NoData from "../components/NoData";
import DeleteModal from "../components/DeleteModal";
import CommentForm from "../components/CommentForm";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const TestComments = observer(() => {
  // этот параметр важен, тк передает id героя, которому надо добавить id skill:
  const [postId, setPostId] = useState(null);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [increment, setIncrement] = useState(0);

  // контроль аккордеона:
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    fetchData();
  }, [increment]);

  async function fetchData() {
    try {
      const posts = await axios.get(`${REACT_APP_API_PORT}api/posts`);
      if (posts.data.length) {
        setPostId(posts.data[0]._id);
        const post = await axios.get(
          `${REACT_APP_API_PORT}api/posts/${posts.data[0]._id}`
        );
        setData(post.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      {data && (
        <Stack spacing={2} sx={{ pb: 4 }}>
          <Typography variant="h5">{`Post ${data.title}`}</Typography>
          <Card>
            <CardHeader
              sx={{ borderBottom: "1px solid #ddd" }}
              title={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <Typography>{`Post ${data.title} comments`}</Typography>
                  <Button variant="contained" onClick={(_) => setVisible(true)}>
                    Add new
                  </Button>
                </Box>
              }
            />
            <CardContent>
              <ErrorBoundary>
                {data.comments && data.comments.length ? (
                  <Fragment>
                    {data.comments.map((x) => (
                      <Accordion
                        key={x._id}
                        expanded={expanded == x._id}
                        onChange={handleChange(x._id)}
                      >
                        <AccordionSummary
                          aria-controls={`${x._id}-content`}
                          id={`${x._id}-header`}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>{x.author}</Typography>
                            <DeleteModal
                              callBack={() => {
                                axios
                                  .delete(
                                    `${REACT_APP_API_PORT}api/comments/${x._id}`,
                                    {
                                      headers: {
                                        "Content-Type": "application/json",
                                        post_id: postId,
                                        "x-auth-token": auth.token,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    console.log(res);
                                    setData({
                                      ...data,
                                      comments: data.comments.filter(
                                        (y) => y._id != x._id
                                      ),
                                    });
                                    setMessage("Document deleted");
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    // logout
                                    auth.logout();
                                  });
                              }}
                            />
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{x.author}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Fragment>
                ) : (
                  <NoData />
                )}
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Stack>
      )}
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
      <Drawer
        anchor="right"
        open={visible}
        onClose={(_) => setVisible(false)}
        PaperProps={{
          sx: { width: "100%", maxWidth: "555px", padding: "25px 15px" },
        }}
      >
        <CommentForm
          callBack={(args) => {
            axios
              .post(
                `${REACT_APP_API_PORT}api/comments`,
                { ...args, postId },
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                setMessage("Document created successfully");
                setVisible(false);
                setData({ ...data, comments: [...data.comments, res.data] });
              })
              .catch((err) => {
                console.log(err);
                // logout
                auth.logout();
              });
          }}
        />
      </Drawer>
    </Fragment>
  );
});

export default TestComments;
