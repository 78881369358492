import React, { Fragment, useState } from "react";
import { Button, Snackbar } from "@mui/material";
import axios from "axios";
import { observer } from "mobx-react";
import auth from "../store/auth";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const SendToSubscribers = observer(({ data }) => {
  const [message, setMessage] = useState("");
  return (
    <Fragment>
      <Button
        onClick={() => {
          axios
            .post(
              `${REACT_APP_API_PORT}api/mail/send`,
              { data: data },
              {
                headers: {
                  "Content-Type": "application/json",
                  "x-auth-token": auth.token,
                },
              }
            )
            .then((res) => {
              console.log(res);
              setMessage("Successfully sent to sunscribers");
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        Send
      </Button>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default SendToSubscribers;
