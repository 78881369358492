import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import TopNav from "./components/TopNav";
import SideNav from "./components/SideNav";
// import MainCreate from "./routes/MainCreate";
import Main from "./routes/Main";
import { aqua } from "./misc/colors";
import Heroes from "./routes/Heroes";
import Files from "./routes/Files";
import Hero from "./routes/Hero";
import Items from "./routes/Items";
import Item from "./routes/Item";
import Advs from "./routes/Advs";
import Adv from "./routes/Adv";
import Video from "./routes/Video";
import Videos from "./routes/Videos";
import Page from "./routes/Page";
import Pages from "./routes/Pages";
import Subscribers from "./routes/Subscribers";
import Gallery from "./routes/Gallery";
import Galleries from "./routes/Galleries";
import User from "./routes/User";
import Users from "./routes/Users";
import Menu from "./routes/Menu";
import Market from "./routes/Market";
import MarketItem from "./routes/MarketItem";
import TestCart from "./routes/TestCart";
import TestComments from "./routes/TestComments";
import Comments from "./routes/Comments";
import Slide from "./routes/Slide";
import Slides from "./routes/Slides";

export default function Private() {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "grid",
        gridTemplateColumns: "200px 1fr",
        height: "100vh",
        width: "100%",
        left: 0,
        top: 0,
        a: {
          color: "inherit",
          textDecoration: "none",
          transition: ".3s ease-in-out",
          "&:hover": { color: aqua },
        },        
      }}
    >
      <BrowserRouter>
        <SideNav />
        <Box>
          <TopNav />
          <Box
            sx={{
              padding: "30px 30px 100px 30px",
              overflowY: "scroll",
              height: "calc(100vh - 105px)",
              scrollbarWidth: "none",
            }}
          >
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="menu" element={<Menu />} />
              <Route path="users/:id" element={<User />} />
              <Route path="users" element={<Users />} />
              {/* <Route path="heroes/:id" element={<Hero />} />
              <Route path="heroes" element={<Heroes />} /> */}
              <Route path="items/:id" element={<Item />} />
              <Route path="items" element={<Items />} />
              <Route path="slides/:id" element={<Slide />} />
              <Route path="slides" element={<Slides />} />
              <Route path="pages/:id" element={<Page />} />
              <Route path="pages" element={<Pages />} />              
              <Route path="banners/:id" element={<Adv />} />
              <Route path="banners" element={<Advs />} />
              <Route path="galleries/:id" element={<Gallery />} />
              <Route path="galleries" element={<Galleries />} /> 
              <Route path="videos/:id" element={<Video />} />
              <Route path="videos" element={<Videos />} />
              <Route path="files" element={<Files />} />
              <Route path="subscribers" element={<Subscribers />} />
              <Route path="market/:id" element={<MarketItem />} />
              <Route path="market" element={<Market />} />
              <Route path="shop" element={<TestCart />} />
              <Route path="comments" element={<Comments />} />
              <Route path="testcomments" element={<TestComments />} />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </Box>
  );
}
