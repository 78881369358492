import React from "react";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  AiOutlineAlignLeft,
  AiOutlineCheckSquare,
  AiOutlineCode,
  AiOutlineFileText,
  AiOutlineFolder,
  AiOutlineFund,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlinePicture,
  AiOutlineProject,
  AiOutlineRead,
  AiOutlineShareAlt,
  AiOutlineTeam,
  AiOutlineYoutube,
} from "react-icons/ai";
import { GiBlackBar, GiChecklist, GiScrollQuill, GiShoppingCart, GiSwitchWeapon } from "react-icons/gi";
import {
  MdContentCopy,
  MdOutlineArticle,
  MdOutlineFolder,
  MdStarRate,
  MdSubject,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { aqua } from "../misc/colors";

export default function SideNav() {
  return (
    <Box
      sx={{
        backgroundColor: "#3d3c42",
        overflowY: "scroll",
        padding: "10px 0",
        scrollbarWidth: "none",
      }}
    >
      <MenuList>
        <NavItem icon={<AiOutlineHome />} label="Dashboard" link="/" />
        <Divider sx={{ borderColor: "#555" }} />
        {/* <NavItem icon={<GiSwitchWeapon />} label="Heroes" link="heroes" /> */}
        <NavItem icon={<AiOutlineRead />} label="Pages" link="pages" />
        <NavItem icon={<MdOutlineFolder />} label="Files" link="files" />
        <NavItem icon={<AiOutlineProject />} label="Menu" link="menu" />
        <NavItem icon={<AiOutlineFileText />} label="Items" link="items" />
        <NavItem icon={<AiOutlineCheckSquare />} label="Banners" link="banners" />
        <NavItem icon={<AiOutlinePicture />} label="Photo galleries" link="galleries" />
        <NavItem icon={<AiOutlineYoutube />} label="Videos" link="videos" />
        <NavItem icon={<AiOutlineMail />} label="Subscribers" link="subscribers" />
        <NavItem icon={<GiBlackBar />} label="Slides" link="slides" />        
        {/* <NavItem icon={<GiBlackBar />} label="Market" link="market" />
        <NavItem icon={<GiScrollQuill />} label="Comments" link="comments" />
        <NavItem icon={<GiShoppingCart />} label="Test shop" link="shop" />
        <NavItem icon={<GiChecklist />} label="Test comments" link="testcomments" /> */}
        <NavItem icon={<AiOutlineTeam />} label="Users" link="users" />
      </MenuList>
    </Box>
  );
}

const NavItem = ({ icon, label, link }) => {
  return (
    <MenuItem
      sx={{
        "*": {
          color: "#fff",
          transition: ".3s ease-in-out",
        },
        "&:hover *": { color: aqua },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        <Link to={link}>{label}</Link>
      </ListItemText>
    </MenuItem>
  );
};
