import React from 'react'
import FileLoad from '../components/FileLoad'

const Files = () => {
  return (
    <div>
      <FileLoad root="common" />
    </div>
  )
}

export default Files