import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { observer } from "mobx-react";
import auth from "../store/auth";
import DeleteModal from "../components/DeleteModal";
import NoData from "../components/NoData";

const REACT_APP_API_PORT = process.env.REACT_APP_API_PORT;

const Comments = observer(() => {
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [increment, setIncrement] = useState(0);
  useEffect(() => {
    axios.get(`${REACT_APP_API_PORT}api/comments`).then((res) => {
      setData(res.data);
    });
  }, [increment]);

  return (
    <Fragment>
      <Stack spacing={2}>
        <Typography
          variant="h5"
          onClick={(_) => {
            console.log("fields");
          }}
        >
          Comments list
        </Typography>
        <Card>
          <CardHeader
            sx={{ borderBottom: "1px solid #ddd" }}
            title="Comments list"
          />
          <CardContent>
            {data && data.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Name</b>
                    </TableCell>
                    <TableCell>status</TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((x) => (
                    <TableRow key={uuidv4()}>
                      <TableCell>{x.author}</TableCell>
                      <TableCell>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={x.status}
                                onChange={(e) => {
                                  axios
                                    .patch(
                                      `${REACT_APP_API_PORT}api/comments/${x._id}`,
                                      { status: e.target.checked },
                                      {
                                        headers: {
                                          "Content-Type": "application/json",
                                          "x-auth-token": auth.token,
                                        },
                                      }
                                    )
                                    .then((res) => {
                                      setIncrement(increment + 1);
                                      setMessage(
                                        "Document updated successfully"
                                      );
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                      // logout
                                      auth.logout();
                                    });
                                }}
                              />
                            }
                            label={x.status ? "active" : "inactive"}
                          />
                        </FormGroup>
                      </TableCell>
                      <TableCell align="right">
                        <DeleteModal
                          callBack={() => {
                            axios
                              .delete(
                                `${REACT_APP_API_PORT}api/comments/${x._id}`,
                                {
                                  headers: {
                                    "Content-Type": "application/json",
                                    "x-auth-token": auth.token,
                                  },
                                }
                              )
                              .then(() => {
                                setData(data.filter((y) => y._id != x._id));
                                setMessage("Document deleted");
                              })
                              .catch((err) => {
                                console.log(err);
                                // logout
                                auth.logout();
                              });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoData />
            )}
          </CardContent>
        </Card>
      </Stack>
      <Snackbar
        open={message.length}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
        message={message}
      />
    </Fragment>
  );
});

export default Comments;
