import React, { Fragment } from "react";
import MainForm from "./MainForm";

export default function VideoForm(props) {
  return (
    <Fragment>
      <MainForm
        {...props}
        fields={[
          {
            name: "title",
            required: true,
            label: "Заголовок",
            type: "string",
          },
          {
            name: "videoId",
            required: true,
            label: "Youtube ID",
            type: "string",
          },
        ]}
      />
    </Fragment>
  );
}
