import React, { Fragment } from "react";
import MainForm from "./MainForm";

export default function AdvForm(props) {
      return (
      <Fragment>
        <MainForm
          {...props}
          fields={[
            {
              name: "title",
              required: true,
              label: "Заголовок",
              type: "string",
            },
            {
              name: "description",
              required: true,
              label: "Описание",
              type: "description",
            },
            {
              name: "text",
              required: true,
              label: "Текст",
              type: "text",
            },
            {
              name: "img",
              required: true,
              label: "Изображение",
              type: "img",
            },
            {
              name: "date",
              required: true,
              label: "Дата",
              type: "date",
            },
          ]}
        />
      </Fragment>
    );
}